.tabItem {
  padding: 5px;
  &:hover {
    border-bottom: 2px solid #c29032;
    cursor: pointer;
  }
  &.active {
    border-bottom: 2px solid #132d5e;
  }
}
.add-button{
  margin-left: 10px !important;
  padding: .2rem .5rem !important;
}
.panel-footer{
  .btn-primary{
    margin-right: 10px;
  }
}

[class*=Page] .content {
  padding: 15px 15px 1rem;
}