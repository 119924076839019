.ShareDraftInstructionForm{
  margin-top: 2rem;
  .row{
    margin-bottom: 2rem;
  }
  .help-block, .errorUpload{
    font-size: 12px;
  }

  .panel-default{
		background-color: #f7f8fa;
		overflow: auto;
        height: 100%;
	}

  .row-eq-height {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
  }


  label {
    font-size: 15px;
	}

	.doc-body{
		border-top: 1px solid #ccc;
	    margin-top: 1rem;
	    padding-top: 2rem;
	}
	.doc-footer{
		background: #eee;
    	padding: 1rem;
        position: absolute;
        bottom: 0px;
        left: 0px;
	}
}

// Extra small devices (portrait phones, less than 576px)
@media (max-width: 480px) {

  .ShareDraftInstructionForm{

    .row-eq-height {
      display: block;
    }

    .panel-default{
      margin-top: 0rem;
    }

    .panel-heading {
      h4 {
        font-size: 14px;
      }
    }
  }
}

// Small devices (landscape phones, 576px and up)
@media (min-width: 576px) and (max-width: 767.98px) {

}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) and (max-width: 991.98px) {

}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) and (max-width: 1199.98px) {

}
