.WorkFlowCodes {
  .activeFields {
    display: flex;
    justify-content: space-between;
    background: #c29032;
    flex: 0 0 100%;
    margin-right: 0px;
    margin-bottom: 0px;
    border-radius: 3px;
  }
  .workFlow-codes{
    > div > div {
      margin-right: 10px;
      margin-bottom: 5px;
    }
    ul {
      max-height: 65vh;
      overflow-y: auto;
    }
  }
  .workFlow-codes-hide{
    display: none !important;
  }
  .workflow-codes-list{
    max-width: 250px;
    min-width: 250px;
    white-space: initial;
  }
  #no-data{
    color: red;
    font-size: inherit;
    font-family: inherit;
  }
}
