.team_member.form-group input {
  margin-left: 5px;
  vertical-align: text-top;
}

.team_member.form-group {
  display: flex;
  align-items: center;
  .switch {
    margin-left: 10px;
  }
}

.team_visibility.form-group {
  display: flex !important;
  align-items: center;
  .switch {
    margin-left: 10px;
  }
}
