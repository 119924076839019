.GlossaryForm {

	.divider {
		margin-top: 2rem;
		padding-bottom: 0.4rem;
		border-top: 2px solid #eee;
	}

	.header {
		line-height: 2rem;
		float: left;
    width: 100%;
	}

	.small-add {
		float: right;
		margin-top: 0.2rem;
		padding: 0.2rem 0.5rem;
	}

	.documentsList{
    text-align: left;
    overflow-y: auto;
    max-height: 90vh;
	}

	.close-btn{
		text-align: right;
		cursor: pointer;
	}

	.form-modal {

		& > div:first-child {
			padding: 1.8rem 1.5rem 2.2rem;
			background-color: #f8f8f9 !important;
			text-align: left;
			max-height: 90vh;
			overflow-y: auto;

			.form-actions {
				text-align: center;
			}
		}

		.form-control {
			&.percentage {
				width: 6rem;
				display: inline;
				margin-right: 0.5rem;
			}
		}
	}

	.editableInput {
		position: absolute;
		top: 0px;
		left: 0px;
		border-right: none;
		width: 90%;

		&:-webkit-autofill, &:focus {
			background-color: #fff !important;
		}
	}

	.table-list{
		i {
			cursor: pointer;
		}
	}

	.nav-tabs>li.active>a, .nav-tabs>li.active>a:focus, .nav-tabs>li.active>a:hover{
		border-top: 0px;
		border-right: 0px;
		border-left: 0px;
	}

	.nav-tabs>li>a:focus {
    outline: 0px auto -webkit-focus-ring-color;
    outline-offset: -2px;
	}

	.GlossaryFormSelectors {
		.table-list{
			th:first-child{
				width: 10%;
			}
			th{
				width: 17%;
      }
      th:last-child{
				width: 22%;
			}
		}
	}

	.GlossaryFormClauses {
		.table-list{
			th:first-child{
				width: 10%;
			}
			th{
				width: 15%;
			}
		}
	}

	.GlossaryFormFields {
		.table-list{
			th:first-child{
				width: 5%;
			}
			th{
				width: 15%;
			}
			th:last-child{
				width: 20%;
			}
		}
	}

	.table-responsive{
		float: left;
		width: 100%
  }

  .sliderPadding{
    padding: 0px;
    & .sliderLabel{
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      padding-left: 10px;
    }
  }

  // .boron-modal {
  //   overflow-y: unset;
  //   .modal-close-btn {
  //     width: 35px;
  //     position: absolute;
  //     right: -15px;
  //     top: -15px;
  //     background: #fff;
  //     height: 35px;
  //     border-radius: 50%;
  //     font-size: 34px;
  //     line-height: 32px;
  //     text-align: center;
  //     box-shadow: 0 1px 20px 0 rgba(0,0,0,.7);
  //     color: #e81e1e;
  //   }
  // }

  .titleDiv {
    display: flow-root;
    padding: 0;
    background-color: #132D5E;
    color: white;
    border: 2px solid #132D5E;
    border-radius: 3px;
    box-shadow: 0 1px 1px rgba(0,0,0,.05);
    margin-bottom: 15px;
    > div {
      padding: 0px;
    }
  }
  .arrowDiv {
    background-color: #132D5E;
    color: white;
    margin: 5px 5px 5px 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    float: right;
  }
  .listDiv{
    background-color: white;
    color: black;
    margin-bottom: 15px;
  }
  .showList{
    display: block !important;
  }
  .hideList{
    display: none;
  }
  .lds-ellipsis {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
  }
  .lds-ellipsis div {
    position: absolute;
    top: 33px;
    width: 13px;
    height: 13px;
    border-radius: 50%;
    background: #132D5E;
    animation-timing-function: cubic-bezier(0, 1, 1, 0);
  }
  .lds-ellipsis div:nth-child(1) {
    left: 8px;
    animation: lds-ellipsis1 0.6s infinite;
  }
  .lds-ellipsis div:nth-child(2) {
    left: 8px;
    animation: lds-ellipsis2 0.6s infinite;
  }
  .lds-ellipsis div:nth-child(3) {
    left: 32px;
    animation: lds-ellipsis2 0.6s infinite;
  }
  .lds-ellipsis div:nth-child(4) {
    left: 56px;
    animation: lds-ellipsis3 0.6s infinite;
  }
  @keyframes lds-ellipsis1 {
    0% {
      transform: scale(0);
    }
    100% {
      transform: scale(1);
    }
  }
  @keyframes lds-ellipsis3 {
    0% {
      transform: scale(1);
    }
    100% {
      transform: scale(0);
    }
  }
  @keyframes lds-ellipsis2 {
    0% {
      transform: translate(0, 0);
    }
    100% {
      transform: translate(24px, 0);
    }
  }
  .search-bar{
    margin-left: 3px;
    display: inline-flex;
    max-width: 65%;
    > input{
      border-radius: 3px 0px 0px 3px;
      width: 100%;
      height: 34px;
      padding: 6px 12px;
      background-color: #fff;
      background-image: none;
      border: 1px solid #ccc;
      box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
      transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
      font-size: 14px;
      line-height: 1.42857;
      color: #555;
    }
    > i {
      font-size: 20px;
      border: 1px solid #cccccc;
      padding: 0px 10px;
      border-radius: 0px 3px 3px 0px;
      color: white;
      background-color: #132D5E;
    }
  }
  .label-arrow-container {
    display: flex;
    justify-content: space-between;
    i {
      padding: 20px;
      font-size: 15px;
    }
  }
}

tr.glossarySortableRow {
	td:first-child{
		width: 10%;
	}
	td{
		width: 15%;
	}

	.edit-btn, .ion-eye {
		padding: 6px 11px;
		font-size: 1.2rem;
		border-radius: 50%;
	}

	.delete-btn {
		padding: 6px 13px;
		font-size: 1.2rem;
		border-radius: 50%;

		&:hover {
			color: #fff;
			background-color: #e23f3f;
		}
		&:focus,
		&:active {
			color: #fff;
			background-color: darken(#e23f3f, 5%);
		}
	}
}

.Showcase_style_handle {
		position: relative;
		top: 1px;
		display: block;
		width: 18px;
		height: 11px;
		opacity: .25;
		margin-right: 20px;
		cursor: row-resize;
		background: -webkit-linear-gradient(top,#000,#000 20%,#fff 0,#fff 40%,#000 0,#000 60%,#fff 0,#fff 80%,#000 0,#000);
		background: linear-gradient(180deg,#000,#000 20%,#fff 0,#fff 40%,#000 0,#000 60%,#fff 0,#fff 80%,#000 0,#000);
}
.import-export-glossaries {
  font-weight: bolder;
  position: relative;
  overflow: auto;
}
.import-export-glossaries .action-import {
  display: inline;
  float: left;
  margin: 0px 10px;
}
.import-export-glossaries .action-export {
  display: inline;
  float: left;
  margin: 0px 10px;
}
.ion-android-archive{
  font-size: 14px;
  margin-right: 3px;
}
.ion-android-exit{
  font-size: 14px;
  margin-right: 3px;
}
.react-loading{
	display: inline-block;
	width: 14px !important;
	height: 18px !important;
	margin-left: 8px;
}

