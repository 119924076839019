.translation-content {
  .switch {
    float: right;
  }
  .language-input {
    display: inline-block;
    width: 44%;
    margin: 0 5%;
  }
  .language-submit {
    margin-top: 15px;
    float: right;
  }
  #iso2 {
    width: 15%;
  }
}
