[class*='Page'] {
  .settings-container{
    padding: 0px;
  }
  .setting-content{
    padding: 1.7rem 35px 5rem 35px;
  }
  .SubTopbar{
    padding: 0 39px;
  }
}
