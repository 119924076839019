.DocumentForm {
  // .boron-modal {
  //   overflow-y: unset;
  //   width: 60% !important;
  //   .modal-close-btn {
  //     width: 35px;
  //     position: absolute;
  //     right: -15px;
  //     top: -15px;
  //     background: #fff;
  //     height: 35px;
  //     border-radius: 50%;
  //     font-size: 34px;
  //     line-height: 32px;
  //     text-align: center;
  //     box-shadow: 0 1px 20px 0 rgba(0,0,0,.7);
  //     color: #e81e1e;

  //   }
  // }

  .activeFields {
    display: flex;
    justify-content: space-between;
    background: #c29032;
    flex: 0 0 100%;
    margin-right: 0px;
    margin-bottom: 0px;
    border-radius: 3px;
  }
  .fieldsContent {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .fieldSizeContent {
    width: 40px;
  }
  .fieldSize {
    width: -webkit-fill-available;
    font-size: .9rem;
    font-weight: 600;
    border-radius: 0 3px 3px 0;
    background-color: #c29032;
    height: 34px;
    color: #fff;
    border-width: 0px;
  }
  .fieldWrap {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    max-height: 75vh;
    overflow-y: auto;
    margin-bottom: 10px;
    justify-content: flex-start;
  }
  .fieldSizeError {
    color: red;
    font-size: 12px;
    margin-bottom: 7px;
  }

}
.btn-add{
  background-color: #c29032;
  color:white !important;
  &:active{
    background-color: #5bc0de;
  }
}
