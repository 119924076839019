/*
 * Sidebar
 */

/* Hide for mobile, show later */

.sidebar {
	position: fixed;
	top: 0;
	bottom: 0;
	left: 0;
	z-index: 1000;
	display: block;
    width: 14rem;
	padding: 20px;
	overflow-x: hidden;
	overflow-y: auto; /* Scrollable contents if viewport is shorter than content. */
	background-color: #132d5e;

    .title {
        margin-top: 12px;
        margin-left: 10px;

        h1 {
            margin-top: 0px;
            color: #fff;
            font-weight: 600;
            font-size: 1.5rem;
            text-transform: capitalize;
            letter-spacing: 0.3rem;
        }
    }

    /* Sidebar navigation */
    .nav-sidebar {
        margin-top: 65px;
        margin-right: -21px; /* 20px padding + 1px border */
        margin-bottom: 20px;
        margin-left: -20px;
        
        ul.subnav {
            display: none;
        }
        .active {
            ul.subnav {
                display: block;
                background-color: rgba(255,255,255,0.1);
                
                a {
                    padding-left: 0px;
                }
            }
        }
    }
    .nav-sidebar li > a {
        display: block;
        padding: 12px 20px;
        padding-left: 30px;
        font-weight: 600;
        color: #fff;
        text-decoration: none;

        &:hover {
            background-color: transparent;
            color: #c29032;
        }
    }
    .nav-sidebar > .active > a,
    .nav-sidebar > .active > a:hover,
    .nav-sidebar > .active > a:focus {
        color: #fff;
        background-color: #c29032;
    }
}
