@media (max-width: 767px) {
	.App {
		padding-left: 0;

		&.sidebar-opened {

			.sidebar, 
			& > .container {
				transform: translate3d(14rem, 0, 0);
			}
		}

		.sidebar {
			left: -14rem;
		}

		.sidebar-icon {
			display: block;
			position: absolute;
			top: 0rem;
			left: 1rem;
			z-index: 10;
			cursor: pointer;
			line-height: 4rem;
			font-size: 2rem;
		}

		.Topbar {
			&.navbar {
				padding-left: 5rem;
			} 
		}
		.boron-modal{
			width: 65% !important;
		}
	}
}

@media (max-width: 600px) {
	.App {
		.boron-modal{
			width: 85% !important;
		}
	}
}