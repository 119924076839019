.SubTopbar {
	// height: 4.2rem;
	padding: 0 45px;
	border-bottom: 1px solid #eee;
	background-color: #fff;

	.main-btns {
		padding-top: 0.7rem;
	}
}
