[class*='Page'] {
  .user-settings-container{
    padding: 0px;
  }
  .user-setting-content{
    padding: 1.7rem 35px 5rem 35px;
  }
  .SubTopbar{
    padding: 0 39px;
  }
    .cate-select {
      width: 100%;
      
      padding: 6px 12px;
      background-color: #fff;
      background-image: none;
      border: 1px solid #ccc;
      border-radius: 0;
      box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
      transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
      border-radius: 3px !important;
      height: 60px;
      margin-top: 5px;
      margin-bottom: 15px;
    }
}
