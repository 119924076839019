.CompanyCategoryList {

	table {
		// tr {
		// 	&:nth-child(2n) {
		// 		background-color: #fff;
		// 	}
		//
		// 	td {
		// 		padding: 10px 8px;
		// 		border: none;
		//
		// 		&:nth-child(1) {
		// 			width: 5rem;
		// 		}
		// 		&:nth-child(3) {
		// 			padding-top: 1.3rem;
		// 			padding-right: 0.4rem;
		// 			width: 3rem;
		// 		}
		// 		&:nth-child(4) {
		// 			padding-top: 1.3rem;
		// 			width: 3rem;
		// 		}
		// 	}
		// }

		.details {
			padding-top: 1.2rem;

			.name {

			}
		}
  }


//   .boron-modal {
//     overflow-y: unset;
//     .modal-close-btn {
//       width: 35px;
//       position: absolute;
//       right: -15px;
//       top: -15px;
//       background: #fff;
//       height: 35px;
//       border-radius: 50%;
//       font-size: 34px;
//       line-height: 32px;
//       text-align: center;
//       box-shadow: 0 1px 20px 0 rgba(0,0,0,.7);
//       color: #e81e1e;

//     }
//   }

}
