@media (max-width: 500px) {
	.App {
		.main-btns {
			display: none;
		}

		.title {
			font-size: 1.4rem !important;
			margin-top: 0.3rem;
		}

		[class*='List'] {
			display: block;
			overflow: auto;
		}

		.GlossaryList {
			.edit-btn,
			.delete-btn {
				display: none;
			}
		}
	}
}
