.LanguageSelector {
  float: right;
  padding-top: 0.2rem;

  // .country-flag {
  //   img {
  //     top: 0;
  //   }
  // }
  .flag-options {
    right: 0;
  }
  .flag-select.form-control {
    padding: 6px 0;
    button {
      margin-top: -5px;
      // .flag-select__option__label {
      //   display: none;
      // }
    }
    img {
      top: unset;
    }
  }
  // .selected--flag--option {
  //   .country-flag {
  //     .country-label {
  //       display: none;
  //     }
  //   }
  // }
}
