.WorkflowCategoryFilter {
	label {
		font-weight: 600;
	}

	select {
		display: inline-block;
		width: inherit;
		margin-left: 1rem;
	}
}


@media (max-width:770px){

	.WorkflowCategoryFilter select {
		margin-left: 0;
		width: 100%;
	}
	.WorkflowSorter {
		text-align: left;
	}
	.WorkflowSorter select {
		margin-left: 0rem;
	}

}

@media (max-width:480px){
	.WorkflowCategoryFilter select {
		margin-left: 0;
		width: 100%;
	}
	

}