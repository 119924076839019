[class*="Page"] {
  height: 100%;
  padding-top: 4rem;
  overflow: hidden;

  .content {
    padding: 1.7rem 35px 5rem 35px;
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
  }
  .collapsible {
    cursor: pointer;
  }
  .collapsible:before {
    content: "> ";
  }
  .sort-by-name {
    float: left;
    margin-top: 0.2rem;
    margin-left: 1.5rem;
    padding: 0.3rem 1.2rem;
    cursor: pointer;
  }
}

.btn,
.btn-default,
.btn.btn-default {
  background-color: #132d5e;
  color: #fff;
  border-radius: 3px;
  //text-transform: capitalize;
}

.btn:hover {
  // background-color: #c29032;
  color: #fff;
  border-radius: 3px;
}

.wrapper.col-sm-6.col-sm-offset-3 {
  margin-top: 20%;
}

.btn.btn-default.delete-btn.ion-trash-b {
  margin-left: 10px;
  margin-right: 10px;
}

.login-page {
  .otp-form-container {
    text-align: center;
  }
  .otp-form {
    .remember-me {
      display: flex;
      justify-content: center;
      p {
        margin-left: 5px;
      }
    }
    button {
      width: 80%;
    }
  }
}
.nav-tabs {
  border-bottom: none !important;
  .nav-link {
    display: inline-block;
    position: relative;
    border: none;
    padding: 1.9rem 0 0.6rem;
    margin-right: 1.7em;
    color: #c29032;
    cursor: pointer;
    background-color: transparent;
    border-bottom: 0.3rem solid #fff;
    text-decoration: none;
    &:hover {
      text-decoration: none;
    }
  }
  .active {
    color: unset !important;
    border-bottom: 0.3rem solid #132d5e;
  }
}

.tab-content {
  .active {
    opacity: 1 !important;
  }
}

.navbar-toggler {
  position: relative;
  float: right;
  padding: 9px 10px;
  margin-right: 0;
  margin-top: 8px;
  margin-bottom: 8px;
  background-color: transparent;
  background-image: none;
  border: 1px solid transparent;
  border-radius: 0;
  border-color: #ddd;

  .navbar-toggler-icon {
    display: inline-block;
    width: 1.5em;
    height: 1.5em;
    vertical-align: middle;
    content: "";
    background: no-repeat 50%;
    background-size: 100% 100%;
    // background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3E%3Cpath stroke='rgba(255, 255, 255, 0.5)' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
  }
}
.navbar-collapse.show{
  border-top: 1px solid transparent;
  border-color: #eee;
}

.boron-modal {
  overflow-y: unset;
  width: 60% !important;
  .modal-close-btn {
    width: 35px;
    position: absolute;
    right: -15px;
    top: -15px;
    background: #fff;
    height: 35px;
    border-radius: 50%;
    font-size: 34px;
    line-height: 32px;
    text-align: center;
    box-shadow: 0 1px 20px 0 rgba(0, 0, 0, 0.7);
    color: #e81e1e;
  }
}

.table-striped {
  tr {
    td {
      vertical-align: middle !important;
    }
  }
}
.ml-20{
  margin-left: 20px;
}
.ml-40{
  margin-left: 40px;
}
