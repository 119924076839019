.EmailSubjectMessageForm {
  .short-codes-container {
    display: flex;
    margin-top: 10px;
    .short-codes-listing {
      list-style: none;
      display: flex;
      font-weight: 400;
      padding: 0px 0px 0px 20px;
      flex-wrap: wrap;
    }
  }
  .page-info-container {
    display: flex;
    justify-content: space-between;
  }
}
