.UserList {

	table {
		tr {
			&:nth-child(2n) {
				background-color: #fff;
			}

			th {
				//text-transform: capitalize;
				opacity: .3;
				font-weight: 600;
			}

			td, th {
				vertical-align: middle !important;
				padding: 10px 8px;
				border: none;

				&:nth-child(1) {
					width: 5rem;
				}

				&:nth-child(2) {

				}

				&:nth-child(3) {
					max-width: 10rem;
					text-align: center;
				}

				&:nth-child(4) {
					max-width: 10rem;
					text-align: center;
				}

				&:nth-child(5) {
					max-width: 6rem;
					padding-right: 2rem;
					text-align: right;
				}
			}
		}

		.details {

			.name {

			}
		}
  }

  .boron-modal {
    // overflow-y: unset;
    // .modal-close-btn {
    //   width: 35px;
    //   position: absolute;
    //   right: -15px;
    //   top: -15px;
    //   background: #fff;
    //   height: 35px;
    //   border-radius: 50%;
    //   font-size: 34px;
    //   line-height: 32px;
    //   text-align: center;
    //   box-shadow: 0 1px 20px 0 rgba(0,0,0,.7);
    //   color: #e81e1e;

    // }
    .form-actions {
      .blockmodalBtn {
        margin: 0 10px;
      }
    }
  }

}

.UserDownloadList{
	.react-datepicker__input-container {
		width: 100%;
	}
	.close-btn {
		float: left;
		width: 100%;
		text-align: right;
	}
}

.UserDraftList{
	.react-datepicker__input-container {
		width: 100%;
	}
	.close-btn {
		float: left;
		width: 100%;
		text-align: right;
	}
}
