.IpAddressesList {
	table {
		tr {
			&:nth-child(2n) {
				background-color: #fff;
			}
			td {
				padding: 10px 8px;
				border: none;
			}
		}
  }

  // .boron-modal {
  //   overflow-y: unset;
  //   .modal-close-btn {
  //     width: 35px;
  //     position: absolute;
  //     right: -15px;
  //     top: -15px;
  //     background: #fff;
  //     height: 35px;
  //     border-radius: 50%;
  //     font-size: 34px;
  //     line-height: 32px;
  //     text-align: center;
  //     box-shadow: 0 1px 20px 0 rgba(0,0,0,.7);
  //     color: #e81e1e;

  //   }
  // }
}
