.GlossaryFormCarousels {
  // .boron-modal {
  //   overflow-y: unset;
  //   width: 60%!important;
  //   .modal-close-btn {
  //     width: 35px;
  //     position: absolute;
  //     right: -15px;
  //     top: -15px;
  //     background: #fff;
  //     height: 35px;
  //     border-radius: 50%;
  //     font-size: 34px;
  //     line-height: 32px;
  //     text-align: center;
  //     box-shadow: 0 1px 20px 0 rgba(0,0,0,.7);
  //     color: #e81e1e;
  //   }
  // }
  .ion-android-list{
    border-radius: 50%;
    padding: 6px 11px;
    font-size: 1.2rem;
  }
  .table-list td:nth-child(5){
    display: flex;
    justify-content: space-evenly;
    >i{
      margin-left: 5px;
      margin-right: 5px;
    }
  }
  .fieldWrap {
    width: 100%;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -ms-flex-direction: row;
    flex-direction: row;
    max-height: 75vh;
    overflow-y: auto;
    margin-bottom: 10px;
    -ms-flex-pack: flex-start;
    justify-content: flex-start;
  }
  .noData {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
  }
  .fieldsContent {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .fieldSizeContent {
    width: 40px;
  }
  .fieldSize {
    width: -webkit-fill-available;
    font-size: .9rem;
    font-weight: 600;
    border-radius: 0 3px 3px 0;
    background-color: #c29032;
    height: 34px;
    color: #fff;
    border-width: 0px;
  }
  .activeFields {
    display: flex;
    justify-content: space-between;
    background: #c29032;
    flex: 0 0 100%;
    margin-right: 0px;
    margin-bottom: 0px;
    border-radius: 3px;
  }
  .fieldSizeError {
    color: red;
    font-size: 12px;
    margin-bottom: 7px;
  }
  .fieldOptionsDrag {
    position: relative;
    top: 1px;
    display: block;
    width: 18px;
    height: 11px;
    opacity: .25;
    margin-right: 5px;
    min-width: 18px;
    cursor: row-resize;
    background: linear-gradient(180deg,#000,#000 20%,#fff 0,#fff 40%,#000 0,#000 60%,#fff 0,#fff 80%,#000 0,#000);
  }
}
