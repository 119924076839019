.GlossaryFormFields {
  // .boron-modal {
  //   overflow-y: unset;
  //   .modal-close-btn {
  //     width: 35px;
  //     position: absolute;
  //     right: -15px;
  //     top: -15px;
  //     background: #fff;
  //     height: 35px;
  //     border-radius: 50%;
  //     font-size: 34px;
  //     line-height: 32px;
  //     text-align: center;
  //     box-shadow: 0 1px 20px 0 rgba(0,0,0,.7);
  //     color: #e81e1e;

  //   }
  // }
  .fieldWrap {
    width: 100%;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -ms-flex-direction: row;
    flex-direction: row;
    max-height: 75vh;
    overflow-y: auto;
    margin-bottom: 10px;
    -ms-flex-pack: flex-start;
    justify-content: flex-start;
  }
  .activeFields {
    display: flex;
    justify-content: space-between;
    background: #c29032;
    border-radius: 3px;
    align-items: center;
  }
  .fieldOptionsDrag {
    position: relative;
    top: 1px;
    display: block;
    width: 18px;
    height: 11px;
    opacity: .25;
    margin-left: 5px;
    cursor: row-resize;
    background: linear-gradient(180deg,#000,#000 20%,#fff 0,#fff 40%,#000 0,#000 60%,#fff 0,#fff 80%,#000 0,#000);
  }
  #selector-options-drag{
    margin-bottom: 0 !important;
    margin-right: 0 !important;
    .label-content{
      padding: 0.5rem 0.5rem !important;
    }
  }
}
