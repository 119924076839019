.SortableItem {
	cursor: grabbing !important;
	cursor: -moz-grabbing !important;
	cursor: -webkit-grabbing !important;
	cursor: move;

	.label-drag {
		opacity: 0.5 !important;
  }

	.label-close {
		position: absolute !important;
		float: none !important;
	}

	.label-content {
		width: 100%;
    background-color: #717882 !important;
  }

  .fieldsContent {
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .activeFields {
    display: flex;
    justify-content: space-between;
    background-color: #717882 !important;
    flex: 0 0 100%;
    margin-right: 0px;
    border-radius: 3px;
  }

  .fieldSize {
    width: -webkit-fill-available;
    font-size: .9rem;
    font-weight: 600;
    border-radius: 0 3px 3px 0;
    background-color: #717882 !important;
    height: 35px;
    color: #fff;
    border-width: 0px;
    justify-content: end;
    direction: rtl;
    padding-right: 20px;
  }
}

.ActiveLabel {
	display: inline-block;
	margin-bottom: 0.5rem;
	margin-right: 0.5rem;
	z-index: 5555;

	&:hover {
		.label-drag {
			opacity: 0.5 !important;
		}
	}

	.label-content {
		padding: 0.5rem 1rem;
		font-size: 0.9rem;
		font-weight: 600;
		border-radius: 3px;
		background-color: #c29032;
		color: #fff;

		&.clickable {
			cursor: pointer;

			&:hover {
				background-color: #132d5e;
			}
		}

		.label-drag {
			float: left;
		    margin-top: .05rem;
		    margin-left: -1rem;
		    padding-left: 0.6rem;
		    padding-right: .5rem;
		    opacity: 0;
		    cursor: move;
		    cursor: grab;
		    cursor: -moz-grab;
		    cursor: -webkit-grab;
		}

		.label-close {
			float: right;
			margin-top: 0.05rem;
			margin-left: 0.6rem;
			font-size: 0.8rem;
			opacity: 0.5;
			cursor: pointer;

			&:hover {
				opacity: 1;
			}
		}
  }

  .step-label-content {

		&.clickable {
			cursor: pointer;

			&:hover {
				background-color: #132d5e;
			}
		}

		.label-drag {
			float: left;
		    margin-top: .05rem;
		    margin-left: -1rem;
		    padding-left: 0.6rem;
		    padding-right: .5rem;
		    opacity: 0;
		    cursor: move;
		    cursor: grab;
		    cursor: -moz-grab;
		    cursor: -webkit-grab;
		}


		.label-close {
			float: right;
			margin-top: 0.05rem;
			margin-left: 0.6rem;
			font-size: 0.8rem;
			opacity: 0.5;
			cursor: pointer;

			&:hover {
				opacity: 1;
			}
		}
	}
}
