.UserSettingsForm {
  margin-top: 25px;
  .row {
    margin-bottom: 2rem;
  }
  .help-block,
  .errorUpload {
    font-size: 14px;
    color: #bdbdbd;
  }

  .dropzone img {
    width: auto !important;
    position: relative !important;
  }
  .panel-body {
    min-height: 550px;
  }

  .panel-default {
    background-color: #f7f8fa;
    overflow: auto;
    height: 100%;
  }

  .panel-body.docpreview {
    background: #fff;
    margin: 1rem;
    padding: 2rem;
    position: relative;
  }

  .row-eq-height {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
  }

  label {
    font-size: 15px;
  }

  .doc-body {
    border-top: 1px solid #ccc;
    margin-top: 1rem;
    padding-top: 2rem;
  }
  .doc-footer {
    background: #eee;
    padding: 1rem;
    position: absolute;
    bottom: 0px;
    left: 0px;
  }

  .setting-left {
    min-width: 250px;
    margin-top: 40px;
    h4 {
      font-size: 28px;
      color: #000000;
      margin-bottom: 45px;
    }

    label {
      font-size: 16px;
      color: #000000;
    }
  }
}

// Extra small devices (portrait phones, less than 576px)
@media (max-width: 480px) {
  .UserSettingsForm {
    .panel-body {
      min-height: auto;
    }

    .panel-body.docpreview {
      background: #fff;
      margin: 1rem;
      padding: 2rem;
      box-shadow: 0 1px 5px 0 rgba(50, 50, 50, 0.5);
      position: relative;
      min-height: 500px;

      .headerpos {
        padding-left: 0;
        padding-right: 0;
      }
    }

    .row-eq-height {
      display: block;
    }

    .panel-default {
      margin-top: 0rem;
    }
    .panel-heading {
      h4 {
        font-size: 14px;
      }
    }
  }
}

// Small devices (landscape phones, 576px and up)
@media (min-width: 576px) and (max-width: 767.98px) {
}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) and (max-width: 991.98px) {
}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) and (max-width: 1199.98px) {
}
