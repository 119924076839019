.Articlesize {
	text-align: right;

	label {
		font-weight: 600;
	}

	select {
		display: inline-block;
		width: inherit;
		margin-left: 1rem;
	}
	.ml-5 {
		margin-left: 5px;
	}
}
