.DraftCategoryFilter {
  display: inline-block;
  // margin: 2.5rem 0 1rem 0;
  // font-size: 0.9rem;
  // float: left;

  label {
    font-weight: 400;
    color: #000000;
  }

  // select {
  //   display: inline-block;
  //   width: inherit;
  //   margin-left: 1rem;
  //   width: 212px !important;
  // }

  // .cate-select
  // {
  //     width: 212px !important;
  // }

  // .cate-name {
  //   width: 212px !important;
  // }

  // @media (max-width: 767px) {
  //   select {
  //     display: inline-block;
  //     width: inherit;
  //     margin-left: 1rem;
  //     width: 140px !important;
  //   }
  // }
}
