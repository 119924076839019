.helpbox-container{
    max-height: 250px;
    overflow: auto;
    .react-loading{
        width: 50px !important;
        height: 50px !important;
    }
    .helpbox-item{
      padding: 10px;
      border: 1px solid #ccc;
      border-bottom: 0;
      &:last-child{
        border-bottom: 1px solid #ccc;
      }
      &.active{
        background: rgb(129, 243, 48);
      }
      &.disabled{
        background: orange;
      }
    }
   
  }
