.CategoryList {

	table {
		tr {
			&:nth-child(2n) {
				background-color: #fff;
			}

			td {
				padding: 10px 8px;
				border: none;
				display: inline-block;

				&:nth-child(1) {
					font-size:2.5rem;
					width: auto;

					span {
						vertical-align: top;
					}
				}
				&:nth-child(3) {
					padding-top: 1.3rem;
					padding-right: 0.4rem;
					width: 3rem;
					float: right;
					margin-right: 1rem;
				}
				&:nth-child(4) {
					padding-top: 1.3rem;
					width: 3rem;
					float: right;
				}
			}
		}
		.curs-pointer {
			cursor: pointer;
		}

		.details {
			padding-top: 0.4rem;
			.child-divider{
				font-size: 2.5rem;
				width: 25px;
				height: 25px;
				border-left: #000000 dashed 1px;
				border-bottom: #000000 dashed 1px;
				display: inline-block;
				margin-right: 10px;
			}

			.name {

			}

			.description {
				opacity: 0.3;

				i {
					margin-right: 0.3rem;
				}
			}
		}
	}

  .boron-modal {
    overflow-y: unset;
    .modal-close-btn {
      width: 35px;
      position: absolute;
      right: -15px;
      top: -15px;
      background: #fff;
      height: 35px;
      border-radius: 50%;
      font-size: 34px;
      line-height: 32px;
      text-align: center;
      box-shadow: 0 1px 20px 0 rgba(0,0,0,.7);
      color: #e81e1e;

    }
  }
}
