.BlockedUserList {

  .boron-modal {
    // overflow-y: unset;
    // .modal-close-btn {
    //   width: 35px;
    //   position: absolute;
    //   right: -15px;
    //   top: -15px;
    //   background: #fff;
    //   height: 35px;
    //   border-radius: 50%;
    //   font-size: 34px;
    //   line-height: 32px;
    //   text-align: center;
    //   box-shadow: 0 1px 20px 0 rgba(0,0,0,.7);
    //   color: #e81e1e;

    // }
    .form-actions {
      .blockmodalBtn {
        margin: 0 10px;
      }
    }
  }
  .ml-5 {
		margin-left: 5px;
  }
  table {
    margin-top: 10px !important;
      tr {
        &:nth-child(2n) {
          background-color: #fff;
        }
  
        th {
          //text-transform: capitalize;
          opacity: .3;
          font-weight: 600;
        }
  
        td, th {
          vertical-align: middle !important;
          padding: 10px 8px;
          border: none;
          &:nth-child(1) {
            width: 5rem;
          }
          &:nth-child(2) {
            padding-right: 0.4rem;

          }
          &:nth-child(3) {
            padding-right: 0.4rem;
            width: 3rem;
            text-align: center;
          }
          &:nth-child(4) {
            padding-right: 0.4rem;
            width: 3rem;
            text-align: center;
          }
          &:nth-child(5) {
            width: 3rem;
            text-align: center;
          }
        }
      }
  
      .details {
  
        .name {
  
        }
  
        .description {
          margin-top: 0.2rem;
          opacity: .4;
          font-size: 0.9rem;
  
          i {
            margin-right: 0.3rem;
          }
        }
      }
  
      .default-logo {
        padding-left: 0.2rem;
      }
    }
}
