.LanguageAddPage{
  // .boron-modal {
  //   overflow-y: unset;
  //   .modal-close-btn {
  //     width: 35px;
  //     position: absolute;
  //     right: -15px;
  //     top: -15px;
  //     background: #fff;
  //     height: 35px;
  //     border-radius: 50%;
  //     font-size: 34px;
  //     line-height: 32px;
  //     text-align: center;
  //     box-shadow: 0 1px 20px 0 rgba(0,0,0,.7);
  //     color: #e81e1e;

  //   }
  // }
  .titleDiv {
    display: flow-root;
    padding: 0;
    background-color: #132D5E;
    color: white;
    border: 2px solid #132D5E;
    border-radius: 3px;
    box-shadow: 0 1px 1px rgba(0,0,0,.05);
    margin-bottom: 15px;
    > div {
      padding: 0px;
    }
  }
  .arrowDiv {
    background-color: #132D5E;
    color: white;
    margin: 5px 5px 5px 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%
  }
  .listDiv{
    background-color: white;
    color: black;
    margin-bottom: 15px;
  }
  .showList{
    display: block !important;
  }
  .hideList{
    display: none;
  }
  .search-bar-holder{
    margin-bottom: 15px;
    .search-bar{
      margin-left: 3px;
      display: inline-flex;
      max-width: 65%;
      > input{
        border-radius: 3px 0px 0px 3px;
        width: 100%;
        height: 34px;
        padding: 6px 12px;
        background-color: #fff;
        background-image: none;
        border: 1px solid #ccc;
        box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
        transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
        font-size: 14px;
        line-height: 1.42857;
        color: #555;
      }
      > i {
        font-size: 20px;
        border: 1px solid #cccccc;
        padding: 0px 10px;
        border-radius: 0px 3px 3px 0px;
        color: white;
        background-color: #132D5E;
      }
    }
  }
  .label-arrow-container {
    display: flex;
    justify-content: space-between;
    i {
      padding: 20px;
      font-size: 15px;
    }
  }
  .react-loading {
    width: 64px!important;
    height: 64px!important;
  }
}
