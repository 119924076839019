.SettingsTabs {
  .left-menu {
    .btn {
      width: 100%;
    }
    a {
      margin-bottom: 10px;
      text-align: left;
    }
  }
  .btn-primary{
    background-color: #C29032 !important
  }
  .navbar-toggle {
    position: relative;
    float: right;
    padding: 9px 10px;
    margin-right: 0;
    margin-top: 8px;
    margin-bottom: 8px;
    background-color: transparent;
    background-image: none;
    border: 1px solid transparent;
    border-radius: 0;
    border-color: #ddd;
  }
}
.image-placeholder{
  width: 20rem !important;
  left: 14px;
  height: 12rem !important;
  position: absolute;
  cursor: grab;
  touch-action: none;
  background: #fff;
}
.close-btn{
  left: 20rem;
  position: absolute;
  border-radius: 50%;
  margin-top: 15px;
  width: 28px;
  color: #fff;
  background: #a9a7a7;
  z-index: 10;
}
input.crop-image-btn {
  margin-top: 20px;
}

input[type=range] {
  display: block;
  width: 100%;
  max-width: 20rem;
}
