.socialShareBlock {
  display: inline-block;

  .valid-emails {
  }

  .emails-input {
    width: calc(100% - 70px);
    display: inline-block;
  }

  .add-email {
    display: inline-block;
    width: 70px;
  }

  .email-tag {
    background-color: #cde69c;
    border-radius: 2px;
    border: 1px solid #a5d24a;
    color: #638421;
    display: inline-block;
    font-family: sans-serif;
    font-size: 13px;
    font-weight: 400;
    margin-bottom: 5px;
    margin-right: 5px;
    padding: 5px;
  }

  .socialDropdown {
    position: absolute;
    width: 50px;
    background-color: #fefefe;
    border: 1px solid #888;
    margin-left: -4px;
    padding: 8px;
    z-index: 1000;
  }
  .socialsToggleBtn {
    vertical-align: middle;
    width: 24px;
    height: 24px;
    font-size: 25px !important;
    color: #333 !important;
  }
  form {
    .form-group {
      margin-bottom: 0;
    }
    label {
      display: block;
      width: 100%;
    }
    input.emails-input {
      width: 100%;
      margin-bottom: 7px;
      margin-top: 7px;
    }
    .valid-emails {
      margin-bottom: 0;
    }
    button.add-email {
      display: none;
    }
    button.form-actions {
      margin-top: 5px;
    }
  }
  .infoModal {
    div {
      width: 100%;
    }
  }
  span {
    div:nth-child(2) {
      width: 100%;
    }
  }
}
