.LoadingScreen{
	display: flex;
    align-items: center;
    justify-content: center;
	position: fixed;
	top: 0px;
	right: 0px;
	bottom: 0px;
	left: 0px;
	z-index: 1051;
	background-color: rgb(55, 58, 71);
	opacity: 0.6;
	.react-loading{
		width: 150px !important;
		height: 150px !important;
	}
}