/*
 * Bootstrap Customization
 */
$icon-font-path: "~bootstrap-sass/assets/fonts/bootstrap/";

// General
$brand-primary: #132d5e;
$brand-danger: #e23f3f;
$border-radius-small: 0px;
$border-radius-base: 0px;
$border-radius-large: 0px;

// Navbar
$navbar-default-bg: #fff;
$navbar-padding-horizontal: 0;
$navbar-padding-vertical: 0;

// Buttons
$btn-default-bg: #eee;
$btn-default-border: #eee;

// Pagination
$pagination-bg: transparent;
$pagination-hover-bg: transparent;

@import "~bootstrap-sass/assets/stylesheets/_bootstrap";

.transation-2s-in-out {
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
.transation-3s-in-out {
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.transation-5s-in-out {
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
}

/*
 * Base structure
 */
html,
body {
  height: 100%;
  font-family: Montserrat, sans-serif;
  font-size: 15px;
  background-color: #f8f8f9;
  font-weight: 600;
}

#root {
  width: 100%;
  height: 100%;
}

.App {
  position: relative;
  padding-left: 14rem;
  width: 100%;
  height: 100%;
  overflow: hidden;

  .sidebar-icon {
    display: none;
  }

  .container {
    position: relative;
    width: 100%;
    height: 100%;
    padding: 0;
    overflow: hidden;
  }

  ul.nav-tabs {
    display: inline-block;
    border: none;

    li {
      padding-right: 2.5rem;

      &.active {
        a,
        a:hover {
          border-bottom: 0.3rem solid #132d5e;
        }
      }

      a,
      a:hover {
        border: none;
        padding: 1.9rem 0 0.6rem 0;
        //text-transform: capitalize;
        color: #c29032;
        cursor: pointer;
        background-color: transparent;
        border-bottom: 0.3rem solid #ffffff;
      }
    }
  }

  .boron-modal {
    max-height: 99%;
    border-radius: 3px;
    // overflow-y: auto;
    overflow-y: unset;


    &.no-body {
      & > div:first-child {
        padding-bottom: 1rem;
      }
    }

    & > div:first-child {
      padding-top: 0.4rem;
      padding-bottom: 2.2rem;
      padding-left: 1rem;
      padding-right: 1rem;
      text-align: center;

      .scrollable {
        max-height: 30rem;
        overflow-y: auto;
        text-align: left;
      }

      h2 {
        font-size: 1.4rem;
        line-height: 2.4rem;
        font-weight: 600;
      }

      p {
        font-weight: 600;
      }

      .form-actions {
        padding-top: 0.7rem;
      }
    }
    .fieldsContainer,
    .allignLeft {
      text-align: justify;
    }

    .cancel {
      background-color: #c29032;
    }

    .modal-close-btn {
      width: 35px;
      position: absolute;
      right: -15px;
      top: -15px;
      background: #fff;
      height: 35px;
      border-radius: 50%;
      font-size: 34px;
      line-height: 32px;
      text-align: center;
      box-shadow: 0 1px 20px 0 rgba(0, 0, 0, 0.7);
      color: #e81e1e;
    }
  }

  form {
    label.control-label {
      font-weight: 600;
    }

    .dropzone {
      background-color: #eee;
      max-width: 20rem;
      height: 12rem;
      font-weight: 600;
      border: 1px dashed #c0c0c0;
      color: #a9a9a9;
      text-align: center;
      line-height: 12rem;
      cursor: pointer;

      img {
        margin-top: -0.4rem;
        max-height: 10rem;
        max-width: 17rem;
      }
    }

    .form-actions {
      margin-top: 2rem;
    }
  }

  .form-actions {
    button {
      &:nth-child(1) {
        margin-right: 0.4rem;
      }

      &:nth-child(2) {
        margin-left: 0.4rem;
      }
    }
  }

  .pagination-wrapper {
    text-align: right;

    .pagination {
      margin: 0px !important;

      li a {
        border: none;
        // border-radius: 50% !important;
      }
    }
  }

  .container-fluid {
    .container {
      padding: 0;
    }
  }

  .item-logo {
    width: 4rem;
    height: 4rem;
    border-radius: 50%;
    background-position: center;
    background-size: cover;
    margin-left: 5px;
    display: inline-block;
  }

  .btn,
  .link-button button {
    border: 1px solid transparent;
    //text-transform: capitalize;
    font-size: 14px;
    border-radius: 3px;
    padding: 0.4rem 0.8rem;
    transition: ease-in-out 0.3s all;

    &:hover,
    &:active,
    &:focus,
    &:visited {
      outline: none;
    }
    i {
      margin-left: 0px;
    }
    span {
      display: none;
      transition: ease-in-out 0.3s all;
    }
    &:hover {
      i {
        margin-left: 4px;
      }
      span {
        display: inline;
      }
    }
  }
  .showDenyMessage {
    margin-left: 1rem;
  }

  .main-btns {
    float: right;
    margin-right: 1.5rem;
    margin-top: 4px;

    a {
      margin-left: 0.8rem;
    }
  }
  .btn-color-change{
    &:hover {
      color: #fff;
      background-color: #132d5e;
    }
    &:active{
      background-color:#a9a9a9;
    }
  }

  .workflow-btn{
    color: #fff;
      background-color: #132d5e;
      &:hover {
        background-color: #5cb85c
      }
  }

  table {
    tr,td{
      border:none !important;
    }
    thead,tr,th{
      border:none !important;
    }
 .edit-btn,
    .ion-eye {
      padding: 6px 11px;
      font-size: 1.2rem;
      border-radius: 50%;
      &:hover {
        color: #fff;
        background-color: #132d5e;
      }
      &:active{
        background-color:#a9a9a9
      }

    }

    .delete-btn,
    .block-btn {
      padding: 6px 13px;
      font-size: 1.2rem;
      border-radius: 50%;

      &:hover {
        color: #fff;
        background-color: $brand-danger;
      }
      &:focus,
      &:active {
        color: #fff;
        background-color: darken($brand-danger, 5%);
      }
    }

    .default-logo {
      background-color: #eee;
      color: #b7b7b7;
      font-size: 2.2rem;
      text-align: center;
      line-height: 3.9rem;
    }
  }

  .pointer {
    cursor: pointer;
  }

  .SettingsTabs {
    .navbar {
      margin-bottom: 0px;
    }
    .navbar-brand {
      padding-top: 15px;
    }
    .navbar-default {
      background-color: #fff;
      border-color: #fff;
      .navbar-nav > .active > a {
        background-color: #fff;
        &:hover {
          background-color: #fff;
        }
      }
    }
    // .nav-tabs {
    //   .nav-link {
    //     display: inline-block;
    //     position: relative;
    //     border: none;
    //     padding: 1.9rem 0 0.6rem;
    //     margin-right: 1.7em;
    //     color: #c29032;
    //     cursor: pointer;
    //     background-color: transparent;
    //     border-bottom: 0.3rem solid #fff;
    //     text-decoration: none;
    //     &:hover {
    //       text-decoration: none;
    //     }
    //   }
    //   .active {
    //     color: unset !important;
    //     border-bottom: .3rem solid #132d5e;
    //   }
    // }
  }

  .maintenance_switch {
    top: 9px;
  }
}

.form-actions > .btn.btn-primary,
.form-actions > .btn.btn-default {
  color:white !important;
  background-color:#132d5e;
  margin-left: 1%;
  margin-bottom: 1%;
  &:active{
   background-color:  #a9a9a9;
    color:black !important;
  }
}

.form-horizontal .control-label {
  text-align: left;
  font-weight: 600;
}

.link-button {
  display: inline-block;
}

.panel.panel-default {
  margin-top: 10px;
}

:focus {
  outline: none !important;
  box-shadow: none !important;
}

