.DocumentCategoryFilter {
	label {
		font-weight: 600;
	}

	select {
		display: inline-block;
		width: inherit;
		margin-left: 1rem;
	}
}


@media (max-width:770px){

	.DocumentCategoryFilter select {
		margin-left: 0;
		width: 100%;
	}
	.DocumentSorter {
		text-align: left;
	}
	.DocumentSorter select {
		margin-left: 0rem;
	}

}

@media (max-width:480px){
	.DocumentCategoryFilter select {
		margin-left: 0;
		width: 100%;
	}
	

}