.WorkflowFilter {
  display: inline-flex;
  // margin: 2.5rem 0 1rem;
  
  // div[class*="-control"] {
  //   min-height: 60px;
  //   min-width: 212px;
  //   margin-top: 5px;
  //   margin-left: 15px;
  // }
  
  div[class*="-option"] {
    padding: 7px;
    flex: 1;
    display: flex;
  }
  
}
@media (max-width: 769px) {
  // .WorkflowFilter {
  //   margin: 0 0 1rem !important;
  // }
  
  // div[class*="-container"] {
  //   margin-left: 10px;
  // }

  // div[class*="-control"] {
  //   min-width: 155px !important;
  //   margin-top: 0px !important;
  //   margin-left: 0px !important;
  // }


}