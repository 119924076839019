.Topbar.navbar {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  height: 4rem;
  padding: 9px 15px 9px 15px;

  .container-fluid {
    padding: 0;

    .title {
      float: left;
      font-size: 1.8rem;
      font-weight: 600;

      a,
      a:focus,
      a:hover {
        text-decoration: none;
        color: #333;
      }

      .divider {
        padding: 0 1rem;
        font-weight: 600;
        color: #eaeaea;
      }
    }
  }

  .template-common-notes {
    width: 75% !important;
    max-height: 90%;
    & > div:first-child {
      text-align: left;
      .scrollable {
		    font-weight: initial;
        max-height: 30rem;
        overflow-y: auto;
      }

      h2 {
        text-align: center;
        margin-top: 10px;
      }
    }
  }
  @media (max-width: 768px) {
    .main-btns {
      display: none;
    }
  }
}

.subnav {
  color: #fff;
}
