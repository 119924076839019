.DocumentSorter {
	text-align: right;
	
	label {
		font-weight: 600;
	}

	select {
		display: inline-block;
		width: inherit;
		margin-left: 1rem;
	}
	.ml-5 {
		margin-left: 5px;
	}
	
}

@media (max-width:770px){
	.DocumentSorter label {
		display: block;
	}
	.DocumentSorter select {
		display: inline-block;
		margin-left: 0;
		width: 100%;
	}

}


@media (max-width:480px){
	.DocumentSorter select {
		display: inline-block;
		width: inherit;
		margin-left: 0;
		width: 100%;
	}
	
}