.UserDraftList {
  .boron-modal {
    overflow-y: unset;
    .modal-close-btn {
      width: 35px;
      position: absolute;
      right: -15px;
      top: -15px;
      background: #fff;
      height: 35px;
      border-radius: 50%;
      font-size: 34px;
      line-height: 32px;
      text-align: center;
      box-shadow: 0 1px 20px 0 rgba(0,0,0,.7);
      color: #e81e1e;

    }
  }

  .workflow-modal{
    .close-btn {
      left: auto !important;
      top: -30px !important;
    }
  }

  margin-top: 10px;
  font-size: 0.9rem;
  display: inline-block;
  width: 100%;

  // @include noselect;

  ul {
    list-style: none;
    padding: 0;
  }

  > ul {
    padding: 0;
    > li {
      // &:nth-child(odd) {background: #ddd}
      > span.button,
      > button {
        float: right;
        margin-left: 10px;
      }
    }
  }

  .socialDropdown {
    li {
      border-bottom: 1px solid #e1e8ee;
      padding: 0.5rem 0;
      margin: 0;
      float: left;
    }
  }

  .socialShareBlock {
    margin-right: 1em;
    // margin-bottom: 1em;
  }

  > ul > li img.icon-info {
    width: 40px;
    &:hover {
      opacity: 0.7;
      cursor: pointer;
    }
  }

  .form-modal {
    form {
      button {
        display: inline-block;
        &.add-email {
          display: none;
        }
      }
    }
  }
  .share-link {
    word-wrap: break-word;
  }

  select {
    display: inline-block;
    width: inherit;
    height: 39px;
  }

  img.icon-info {
    width: 40px;
    &:hover {
      opacity: 0.7;
      cursor: pointer;
    }
  }

  .share-fields-box {
    height: 35vh;
    overflow: auto;
    hr {
      margin-top: 10px;
      margin-bottom: 10px;
      border: 0;
      border-top: 1px solid #eee;
    }
  }

  .template-name {
    height: 39px;
    // line-height: 39px;
  }

  .btn {
    &.edit {
      margin-right: 1em;
      margin-left: 1em;
    }

    &.edit,
    &.share,
    &.permissions {
      padding: 0 !important;
      height: 39px;
      width: 39px;

      i {
        margin: 0 !important;

        &:hover {
          margin: 0 !important;
        }
      }
    }
  }

  .editable-fields-box {
    margin-bottom: 1rem;
  }

  .ion-edit:hover {
    cursor: pointer;
  }

  .react-loading-container {
    display: flex;
    align-items: center;
    justify-content: center;
    .loading-message {
      font-family: inherit;
      font-size: inherit;
      font-weight: 600;
    }
  }

  table {
    thead {
      // border: 1px solid #bfbfbf;

      .column-name {
        -webkit-border-top-left-radius: 10px;
        -moz-border-radius-topleft: 10px;
        border-top-left-radius: 10px;
      }

      .column-deleteColumn {
        -webkit-border-top-right-radius: 10px;
        -moz-border-radius-topright: 10px;
        border-top-right-radius: 10px;
      }
    }

    tbody {
      border: 1px solid #e1e8ee;
      border-top: none;
      margin: 20px;
      border-radius: 3px;
    }
    .edit {
      cursor: pointer;
      margin-right: 35px;
      font-size: 20px !important;
      color: #132d5e !important;
    }

    .cate-select1 {
      border: #dadada solid 1px !important;
      width: 130px !important;
      height: 40px !important;
      box-shadow: none;
      margin-top: 10px !important;
      margin-bottom: 10px !important;
    }

    .name {
      width: 540px;
    }

    .shareColumn {
      width: 140px;
    }

    .documentName {
      width: 280px;
    }

    @media (max-width: 999px) {
      .draftReUse,
      .column-draftReUse {
        display: none;
      }
    }
    .draftReUse {
      width: 150px;
    }

    .name a {
      color: #000000;
      font-size: 14px;
    }

    .documentName a {
      color: #000000;
      font-size: 14px;
    }

    .cate-share {
      width: 18px !important;
      height: 18px !important;
      color: #fff;
      font-size: 13px !important;
      border-radius: 3px;
      background: none;
      display: block;
      text-align: center;
      cursor: pointer;
      i {
        color: #333;
        font-size: 24px;
      }
    }
    .set-reminder {
      color: #fff;
      border-radius: 3px;
      background: #ffffff;
      display: block;
      text-align: center;
      line-height: 13px;
      // margin-top: 17px;
      cursor: pointer;
      i {
        font-size: 24px !important;
        color: #333;
      }

      .set-reminder:hover {
        background: none !important;
      }
    }

    .cancel {
      position: relative;
      top: 2px;
      a {
        color: #000000;
        cursor: pointer;
        i {
          color: #e81e1e;
          font-size: 18px;
          margin-right: 15px;
        }
      }
    }
    th {
      padding: 20px 20px !important;
    }
    td {
      position: relative;
      padding: 0px 20px !important;
      text-align: left;
      border: none !important;

      &.deleteColumn {
        text-align: center;
      }
    }

    th {
      background: #132d5e;
      color: #fff;
      font-weight: 400;
    }

    tr {
      &:nth-child(2n + 1) {
        background-color: #fff;
        border-bottom: #d9d9d9 solid 1px;
      }

      &:nth-child(2n) {
        background-color: #fff;
        border-bottom: #d9d9d9 solid 1px;
      }

      &:last-child {
        border-bottom: none;
        .name {
          -webkit-border-bottom-left-radius: 10px;
          -moz-border-radius-bottomleft: 10px;
          border-bottom-left-radius: 10px;
        }
      }
    }

    @media (max-width: 767px) {
      .column-shareColumn {
        -webkit-border-top-right-radius: 10px;
        -moz-border-radius-topright: 10px;
        border-top-right-radius: 10px;
      }
    }

    .set-reminder {
      i {
        margin: 0 !important;
        &:hover {
          margin: 0 !important;
        }
      }
    }
  }

  .boron-modal {
    border-radius: 3px;
    -webkit-box-shadow: 0 1px 20px 0 rgba(0, 0, 0, 0.7);
    -moz-box-shadow: 0 1px 20px 0 rgba(0, 0, 0, 0.7);
    box-shadow: 0 1px 20px 0 rgba(0, 0, 0, 0.7);
    text-align: center;
    .close-btn {
      width: 35px;
      position: absolute;
      right: -15px;
      top: -15px;
      background: #fff;
      height: 35px;
      border-radius: 50%;
      font-size: 34px;
      line-height: 32px;
      text-align: center;
      box-shadow: 0 1px 20px 0 rgba(0, 0, 0, 0.7);
      color: #e81e1e;
      i {
        margin-right: 0px;
        font-size: 34px;
      }
    }

    .draftName {
      margin-bottom: 10px;
    }

    button {
      margin-top: 30px;
    }

    .btn {
      margin-right: 2%;
      i {
        margin-right: 10px;
      }
    }
    .shareable-fields {
      text-align: left;
    }
    .instruction-message {
      padding-top: 15px;
    }
  }

  .btn1,
  .btn2,
  .btn3,
  .btn4 {
    height: 60px;
    border-radius: 3px;
    border: none;
    color: #ffffff;
    font-size: 18px;
    background: #132d5e;
    padding-left: 50px;
    padding-right: 50px;
  }

  .btn2 {
    background: #c29032 !important;
  }

  .btn3 {
    background: #535353 !important;
  }

  .btn4 {
    background: #e81e1e !important;
  }
  .defaultMsg {
    padding: 25px;
    text-align: center;
    font-size: 16px;
  }

  .badge-secondary {
    background-color: #132d5e;
    border-radius: 2px;
    font-weight: 100;
    margin-right: 1px;
  }

  .card-header {
    button {
      margin-top: 10px;
      margin-left: 5px;
    }
  }
  .card-body {
    ul {
      padding-left: 18px;
      li {
        overflow-x: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
      li:hover {
        white-space: unset;
      }
    }
  }
}

@media (max-width: 767px) {
  .UserDraftList {

    margin-top: 0px !important;
    table {
      .cate-select1 {
        margin-left: 0px !important;
        margin-top: 0px !important;
        margin-bottom: 0px !important;
      }
      td {
        padding: 10px 10px !important;
      }
      .name span {
        float: right;
        position: absolute;
        right: 12px;
        background: #132d5e;
        color: #fff;
        padding: 6px 16px;
        border-radius: 3px;
        margin-top: 0 !important;
        &::before {
          content: "Delen";
        }
      }
    }
    .cate-share {
      position: relative;
      top: -4px;
    }

    .shareColumn {
      width: 115px;
    }

    .cancel {
      position: relative;
      top: -7px !important;
    }

    .Draftpage {
      select {
        margin-left: 0px !important;
      }
    }
  }

  .react-collapsible .expanded-row p.child-cell:nth-child(3) .child-label {
    display: none;
  }
  .react-collapsible .expanded-row p.child-cell:nth-child(3) .child-content {
    position: absolute;
    right: 35%;
    margin-top: 12px;
    width: auto;
    z-index: 1;
  }
  .react-collapsible .expanded-row p.child-cell:nth-child(4) {
    position: relative;
  }
  .react-collapsible .expanded-row p.child-cell:nth-child(5) .child-label {
    display: none;
  }
  .react-collapsible .expanded-row p.child-cell:nth-child(5) .child-content {
    position: absolute;
    right: 26%;
    margin-top: -50px;
    width: auto;
  }
  .react-collapsible .expanded-row p.child-cell:nth-child(4) .child-content {
    width: auto;
  }
  .DraftsList table thead .column-name {
    border-top-left-radius: 10px;
  }
  .DraftsList table .cate-share {
    width: 21px !important;
    height: 24px !important;
    color: #fff;
    position: relative;
    top: 1px;
  }
}
