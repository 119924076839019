.RequiredOptionsList {
  font-size: 0.9rem;
  margin-top: 40px;
  .panel-default {
    background-color: #f7f8fa;
    overflow: auto;
  }
  .list-group {
    margin-bottom: 0;
  }

  .list-group-item {
    background-color: #f7f8fa;
    align-items: center;
    display: flex;
    background-color: #ffffff;
    overflow: auto;
    border-radius: 3px !important;
  }

  .ml-auto {
    margin-left: auto;
  }
}

// Extra small devices (portrait phones, less than 576px)
@media (max-width: 480px) {
  .RequiredOptionsList {
  }
}
