.CarouselOption {
  .ml-0 {
    margin-left: 0px !important;
  }
  .mb-10 {
    margin-bottom: 10px !important;
  }
  .flex-title {
    display: flex;
    flex-shrink: 4px;
    justify-content: space-around;
  }
  .set-time {
    width: 18%;
  }
  .documents-selected {
    width: 100%;
  }
  .titleDiv {
    display: flow-root;
    padding-left: 10px;
    background-color: #132d5e;
    color: #fff;
    border: 2px solid #132d5e;
    border-radius: 3px;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
    margin-bottom: 15px;
  }
  .titleDiv .title-name {
    width: 90%;
    float: left;
    line-height: 40px;
  }
  .arrowDiv {
    background-color: #132d5e;
    color: #fff;
    margin: 5px 5px 5px 0;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: center;
    justify-content: center;
    -ms-flex-align: center;
    align-items: center;
    height: 100%;
    float: right;
    padding-bottom: 10px;
  }
  .arrowDiv .arrow-icon {
    font-size: 15px;
    margin-top: 15px;
    cursor: pointer;
  }
  .arrowLeft {
    background-color: #132d5e;
    color: #fff;
    margin: 5px 5px 5px 0;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: center;
    justify-content: center;
    -ms-flex-align: center;
    align-items: center;
    height: 100%;
    float: left;
  }
  .titleDiv .buttonDelete {
    padding: 13px 14px;
  }
  .form-actions .btn-danger {
    margin-bottom: 7px !important;
  }
  .btn-change {
    background-color: #696565;
  }
  .setOrder {
    width: 20%;
    float: left;
  }
  .chooseOder {
    width: 25%;
    float: left;
    margin-left: 20px;
  }
  .has-error {
    color: #a94442;
  }
}
