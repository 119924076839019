.DocumentList {
  // .boron-modal {
  //   overflow-y: unset;
  //   .modal-close-btn {
  //     width: 35px;
  //     position: absolute;
  //     right: -15px;
  //     top: -15px;
  //     background: #fff;
  //     height: 35px;
  //     border-radius: 50%;
  //     font-size: 34px;
  //     line-height: 32px;
  //     text-align: center;
  //     box-shadow: 0 1px 20px 0 rgba(0, 0, 0, 0.7);
  //     color: #e81e1e;
  //   }
  // }

  table {
    tr {
      &:nth-child(2n) {
        background-color: #fff;
      }

      th {
        // text-transform: uppercase;
        // opacity: .3;
        // font-weight: 400;
      }

      td,
      th {
        vertical-align: middle;
        padding: 10px 8px;
        border: none;

        // &:nth-child(1) {
        // 	width: 5rem;
        // }
        &:nth-child(3) {
          text-align: center;
        }
        &:nth-child(4) {
          text-align: center;
        }
        &:nth-child(5) {
          padding-right: 0.4rem;
          // width: 3rem;
        }
        &:nth-child(6) {
          // width: 3rem;
        }
      }
    }

    .details {
      .name {
      }

      .description {
        margin-top: 0.2rem;
        opacity: 0.4;
        font-size: 0.9rem;
        padding: 6px 5px;

        i {
          margin-right: 0.3rem;
        }
      }
    }

    .default-logo {
      padding-left: 0.2rem;
    }
  }

  .error-info-modal > div:first-child {
    text-align: left !important;
    h4 {
      text-align: center;
    }
  }
}
.tablet_mob {
  display: block;
}

.description {
  margin-top: 0.2rem;
  margin-left: 10%;
  font-size: 0.9rem;
  color: #b3b3b3;
  white-space: nowrap;
  float: left;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 6px 5px;
}

.far.fa-file-alt.file-icon {
  font-size: 27px;
  margin-right: 10px;
  float: left;
  margin-top: 0;
  color: #132d5e;
}
.DocumentList {
  .details {
    color: #132d5e;

    .description {
      margin-top: 0;
      opacity: 0.4;
      font-size: 0.9rem;
      margin-left: 10px;
      color: #b3b3b3;
    }
  }
}
.main_doc_rw {
  margin-bottom: 15px;
  border-bottom: 1px solid #e1e8ee;
  padding-bottom: 8px;
}
.document-box {
  display: inline-block;
  width: 100%;
  margin-bottom: 0px;

  .docname {
    border-radius: 3px;
    .item-logo {
      float: left;
      margin-right: 13px;
    }
    width: 45%;
    float: left;
    border: 2px solid #132e5e;
    padding: 6px 5px;
  }
  .btn-details-doc {
    float: right;
    span {
      display: inline-block !important;
    }
    span.details-text {
      margin-right: 8px;
      color: white;
    }
    &:hover {
      i {
        margin-left: 0;
      }
    }
  }
  .default-logo {
    padding-left: 0.2rem;
    padding-left: 0.2rem;
    background-color: #eee;
    color: #b7b7b7;
    font-size: 1.2rem;
    text-align: center;
    line-height: 2.5rem;
    width: 35px;
    height: 35px;
  }
  .name {
    height: 20px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
    white-space: nowrap;
  }
}
.document_detail {
  background: #f5f5f5;
  padding: 20px 26px;
  border: 1px solid #dadada;
  margin-top: 10px;
  margin-bottom: 10px;
  .item {
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    span {
      width: 25%;
      display: inline-block;
      span {
        width: auto;
      }
    }
  }
}
.DocumentListPage {
  .content {
    padding: 1.7rem 15px 5rem;
  }
}

@media (max-width: 1024px) {
  .document_detail .item span {
    width: 36%;
    display: inline-block;
  }

  .description {
    margin-left: 5%;
    width: 15%;
  }
}

@media (max-width: 770px) {
  .tablet_mob {
    display: block;
  }
  table.table.table-striped.tab-hidden {
    display: none;
  }
  .document_detail .item span {
    width: 46%;
    display: inline-block;
  }
}

@media (max-width: 480px) {
  .document-box {
    .docname {
      .item-logo {
        display: none;
      }
      width: 50%;
      float: left;
    }
  }
  .description {
    display: none;
  }
}
