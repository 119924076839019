.SearchBar {
  input {
    // margin-left: 0.8rem;
    width: 12rem;
  }
  .form-control {
    width: 100%;
    height: 34px;
    padding: 6px 12px;
    background-color: #fff;
    background-image: none;
    border: 1px solid #ccc;
    border-radius: 0;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    border-radius: 3px !important;
    height: 60px;
    // margin-top: 5px;
    margin-bottom: 15px;
  }

}
